export const RESOURCE_LIST = [
  {
    title: `Brief of SPACE$M$AP services`,
    subtitle:
      "Real-time Web Server for Safer, more Sustainable Space (AMOS, 2022)",
    link: "/resources/AMOS-Technical-Paper-SPACEMAP.pdf"
  },
  {
    title: "Brief of SPACE$M$AP algorithm",
    subtitle:
      "Conjunction management for satellite constellations (AMOS, 2020)",
    link: "/resources/DOC_03_The_brief_of_SPACEMAP_algorithm_AMOS2020.pdf"
  },
  {
    title: "Initial idea of SPACE$M$AP algorithm",
    subtitle:
      "Innovative Conjunction Prediction using Voronoi-filter (AMOS, 2017)",
    link: "/resources/DOC_02_The_initial_idea_of_SPACEMAP_algorithm_AMOS2017.pdf"
  },
  {
    title: "Watcher-Catcher Service algorithm",
    subtitle:
      "A Real-time Function to Predict Spy Satellites in Timeline (IAC, 2022)",
    link: "/resources/IAC2022-WatcherCatcher.pdf"
  },
  {
    title: "Introduction of Astro-$1$",
    subtitle: "Real-time Conjunction Assessment (KSAS, 2023)",
    link: "/resources/AstroFingertip.pdf"
  },
  {
    title: "Introduction of Astro$L$ibrary",
    subtitle: "Real-time Spatiotemporal Reasoning (KSAS, 2023)",
    link: "/resources/astrolibrary.pdf"
  },
  {
    title: "Introduction of Astro$L$ibrary (Proof)",
    subtitle:
      "Real-time Conjunction Assessment and Optimal Collision Avoidance (JSSE, 2024)",
    link: "https://doi.org/10.1016/j.jsse.2024.07.003"
  }
];
