import "./App.css";
import { isMobile } from "react-device-detect";
import NavigationBar from "./components/navigation-bar/NavigationBar";
import Body from "./page/Body";
import CurrentMenuBar from "./components/ui/CurrentMenuBar";
import MobileNavBar from "./components/navigation-bar/MobileNavBar";
import MobileBody from "./page/MobileBody";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./page/privacy/PrivacyPolicy";
import SpaceCalendar from "./page/calendar/SpaceCalendar";
import NewBody from "./page/NewBody";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              isMobile ? (
                <>
                  <MobileNavBar />
                  <MobileBody />
                </>
              ) : (
                <>
                  <NavigationBar />
                  <NewBody />
                  {/* <CurrentMenuBar /> */}
                  {/* <Body /> */}
                </>
              )
            }
          />
          <Route path="/spacecalendar/*" element={<SpaceCalendar />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
