import { useEffect } from "react";

const useObserver = id => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.transition = "opacity 1s";
            entry.target.style.opacity = "1";
          } else {
            entry.target.style.opacity = "0";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5
      }
    );
    const target = document.getElementById(`${id}`);

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        target.style.transition = "opacity 0.9s";
        target.style.opacity = "0";
        observer.unobserve(target);
      }
    };
  }, []);
};

export default useObserver;
