import React, { useState, useRef, useEffect } from "react";
import useWindowSize from "@/hooks/page/useWindowSize";
import styled from "styled-components";
import Astro1 from "./product/Astro1";
import AstroOrca from "./product/AstroOrca";
import AstroLibrary from "./product/AstroLibrary";
import STAI from "./technology/STAI";
import Voronoi from "./technology/Voronoi";
import Resources from "./technology/Resources";
import About from "./company/About";
import Mission from "./company/Mission";
import Team from "./company/Team";
import News from "./news/News";
import { NUM_PAGE } from "@/constants/nav_bar";
import Contact from "./company/Contact";
import Home from "./Home";
import useCookie from "@/hooks/calendar/useCookie";
import { ACCESS_TOKEN, LOCALSTORAGE_KEY } from "@/constants/calendar";

const Body = () => {
  const { height } = useWindowSize();
  const { getCookie } = useCookie();

  const [index, setIndex] = useState(0);
  const ViewerRef = useRef(null);
  const isScrolling = useRef(false);

  useEffect(() => {
    function handleClickSubMenu(event) {
      setIndex(event.detail.index);
    }

    //google login success
    // if (getCookie(ACCESS_TOKEN) && sessionStorage.getItem(ACCESS_TOKEN)) {
    //   setIndex(12);
    //   sessionStorage.removeItem(ACCESS_TOKEN);
    // }

    //clicked Contact in SpaceCalendar
    if (localStorage.getItem(LOCALSTORAGE_KEY)) {
      setIndex(11);
      localStorage.removeItem(LOCALSTORAGE_KEY);
    }

    document.addEventListener("onSubMenuClick", handleClickSubMenu);
    return () => {
      document.removeEventListener("onSubMenuClick", handleClickSubMenu);
    };
  }, []);

  useEffect(() => {
    const onChangeMenu = new CustomEvent("onChangeMenu", {
      bubbles: false,
      cancelable: false,
      detail: {
        index: index
      }
    });
    document.dispatchEvent(onChangeMenu);

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.style.transition = "opacity 1.5s";
            entry.target.style.opacity = "1";
          } else {
            entry.target.style.opacity = "0";
          }
        });
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5
      }
    );
    const target = document.getElementById(`content${index}`);

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        target.style.transition = "opacity 0.9s";
        target.style.opacity = "0";
        observer.unobserve(target);
      }
    };
  }, [index]);

  useEffect(() => {
    ViewerRef.current.focus();
  }, [ViewerRef.current]);

  const updateIndex = (isUpMoving, handleIsScrolling) => {
    const isFirst_or_Last =
      (index === 0 && isUpMoving) || (index === NUM_PAGE && !isUpMoving);
    if (isFirst_or_Last) {
      isScrolling.current = false;
      return;
    }

    setIndex(current => {
      const nextIndex = isUpMoving
        ? Math.max(current - 1, 0)
        : Math.min(current + 1, NUM_PAGE);

      return nextIndex;
    });

    setTimeout(() => {
      handleIsScrolling(false);
    }, 1000);
  };

  const handleIsScrolling = isScroll => {
    isScrolling.current = isScroll;
  };

  const onWheel = e => {
    if (isScrolling.current) return;

    handleIsScrolling(true);

    const isUpMoving = e.deltaY < 0;
    updateIndex(isUpMoving, handleIsScrolling);
  };

  const onKeyDown = e => {
    if (isScrolling.current) return;
    handleIsScrolling(true);

    if (e.key === "PageUp" || e.key === "ArrowUp") {
      updateIndex(true, handleIsScrolling);
    } else if (e.key === "PageDown" || e.key === "ArrowDown") {
      updateIndex(false, handleIsScrolling);
    } else {
      handleIsScrolling(false);
    }
  };

  return (
    <PageViewer
      ref={ViewerRef}
      $height={height}
      onWheel={onWheel}
      onKeyDown={onKeyDown}
      tabIndex={-1}
    >
      <PageContainer id="pageContainer" $offset={height * index * -1}>
        <Home />
        <Astro1 />
        <AstroOrca />
        <AstroLibrary />
        <STAI />
        <Voronoi />
        <Resources />
        <News />
        <About />
        <Mission />
        <Team />
        <Contact />
      </PageContainer>
    </PageViewer>
  );
};

export default React.memo(Body);

const PageViewer = styled.section`
  position: relative;
  width: 100%;
  height: ${props => `${props.$height}px`};
  overflow: hidden;
`;

const PageContainer = styled.main`
  width: auto;
  height: auto;
  transform: translateY(${props => `${props.$offset}px`});
  transition: transform ease 0.9s;
  background-color: #ffffff;
`;
